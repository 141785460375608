import FullScreenLoader from '@/components/FullScreenLoader/FullScreenLoader.vue'
import ManufacturerForm from '@/components/ManufacturerForm/ManufacturerForm.vue'
import GraphQlMixin from '@/mixins/graphql.mixin'
import { mapMutations } from 'vuex'
import Datepicker from 'vuejs-datepicker'

export default {
  props: ['id'],
  mixins: [GraphQlMixin],
  components: {
    FullScreenLoader,
    ManufacturerForm,
    Datepicker
  },
  data() {
    return {
      validating: false,
      submitting: false,
      model: {
        name: null,
        assetType: null,
        modelNumber: null,
        modelYear: null,
        cmanageModel: null,
        manufacturer: null
      },
      manufacturers: [],
      showAddManufacturerDialog: false,
      rules: {
        required: [v => !!v || 'Required']
      },
      whereConditions: [],
      errorMessages: {
        name: [],
        modelNumber: []
      },
      debounceTimeout: null
    }
  },
  computed: {
    loading() {
      return this.$apollo.queries.model.loading
    },
    loaderMessage() {
      return this.loading ? 'Loading model' : this.submitting ? 'Submitting' : ''
    },
    fieldClass() {
      return {
        'px-0': this.$vuetify.breakpoint.xsAndDown,
        'px-4': this.$vuetify.breakpoint.smAndUp
      }
    },
    assetTypes() {
      return _.map(_.filter(this.$store.getters['graphql/assetTypes'], at => at.graphTypeName !== 'Asset'), o => _.assign(o, { label: _.startCase(o.graphTypeName)}))
    },
    disabledDates() {
      return {
        from: this.$moment().add(1, 'Y')._d
      }
    },
    calendarValue() {
      if (this.model.modelYear) {
        return this.$moment(`${this.model.modelYear}-06-01`, 'YYYY-MM-DD')._d
      }
      return null
    }
  },
  methods: {
    async validateField(value, path, additionalRules = []) {
      this.whereConditions = [{ path, comparison: 'equal', value }]
      if (this.id) {
        this.whereConditions.push({ path: 'id', comparison: 'equal', value: this.id, negate: true })
      }
      // check other conditions if they have values
      for (let rule of additionalRules) {
        if (rule.value) {
          this.whereConditions.push(rule)
        } else {
          this.errorMessages[path].push(rule.path + ' not selected')
        }
      }

      if (!_.isEmpty(this.whereConditions)) {
        const { data, error } = await this.$apollo.query({
          // Query
          query: this.$gql`
            query models($where: [WhereExpression]) {
              models(where: $where) {
                totalCount
              }
            }
          `,
          variables: { where: this.whereConditions }
        })
        if (data && data.models) {
          const models = data.models
          const msg = `Model with the same ${this.whereConditions[0].path} exists`
          if (models.totalCount > 0) {
            this.errorMessages[this.whereConditions[0].path].push(msg)
          } else {
            this.errorMessages[this.whereConditions[0].path] = _.remove(
              this.errorMessages[this.whereConditions[0].path],
              msg
            )
          }
        } else if (error) {
          this.graphQLOnError(`Failed to validate model data with server. ${error.toString()}`)
          this.assets = {}
        } else {
          this.graphQLOnError('Failed to connect to server.')
          this.assets = {}
        }
      }
    },
    async validateName() {
      await this.validateField(this.model.name, 'name')
    },
    async validateModelNumber() {
      await this.validateField(this.model.modelNumber, 'modelNumber')
    },
    async validate() {
      this.validating = true

      // reset validation
      this.$refs.form.resetValidation()
      this.errorMessages = {
        name: [],
        modelNumber: []
      }

      // validate
      await this.validateName()
      await this.validateModelNumber()
      const isValid = this.$refs.form.validate() && _.every(this.errorMessages, o => o.length === 0)
      this.validating = false
      if (isValid) {
        this.submit()
      }
    },
    submit() {
      // remove errors
      this.removeErrorByType('form')

      // prepare payload
      const input = {
        name: this.model.name,
        assetType: this.model.assetType,
        modelNumber: this.model.modelNumber,
        modelYear: this.model.modelYear ? this.model.modelYear : null,
        cmanageModel: this.model.assetType == 'Sim' ? this.model.cmanageModel : null,
        manufacturerId: _.get(this.model, 'manufacturer.id')
      }
      const fields = this.getFieldsGql('read', 'Model', [
        'id',
        'name',
        'assetType',
        'modelNumber',
        'modelYear',
        { name: 'manufacturer', type: 'Manufacturer', fields: ['id', 'name'] }
      ])
      // send by form type
      this.submitting = true
      if (this.id) {
        input.id = this.id
        this.$apollo
          .mutate({
            // Query
            mutation: this.$gql`
              mutation updateModel($input: UpdateModelInput!) {
                updateModel(input: $input) {
                  model {
                    ${fields}
                  }
                }
              }
            `,
            variables: { input }
          })
          .then(({ data }) => {
            const model = _.get(data, 'updateModel.model')
            this.setAppSnackbar({
              text: `Updated model #${model.id} ${model.name} successfully.`,
              color: 'success'
            })
            this.submitting = false
            this.$router.push({ name: 'list-models', params: { updatedItem: model } })
          })
          .catch(e => {
            this.addError(null, `Failed to update model. ${e.toString()}`, 'form')
            this.submitting = false
          })
      } else {
        this.$apollo
          .mutate({
            // Query
            mutation: this.$gql`
              mutation createModel($input: CreateModelInput!) {
                createModel(input: $input) {
                  model {
                    ${fields}
                  }
                }
              }
            `,
            variables: { input }
          })
          .then(({ data }) => {
            const model = _.get(data, 'createModel.model')
            if (model && model.id && model.name) {
              this.setAppSnackbar({
                text: `Created model #${model.id} ${model.name} successfully.`,
                color: 'success'
              })
              this.submitting = false
              this.$router.push({ name: 'list-models', params: { updatedItem: model } })
            } else {
              this.addError(null, 'Failed to create model.', 'form')
              this.submitting = false
            }
          })
          .catch(e => {
            this.addError(null, `Failed to create model. ${e.toString()}`, 'form')
            this.submitting = false
          })
      }
    },
    cancel() {
      if (history.length > 1) {
        history.go(-1)
      } else {
        this.$router.push({ name: 'list-models' })
      }
    },
    onManufacturerCreated(manufacturer) {
      this.manufacturers.push(manufacturer)
      this.model.manufacturer = manufacturer
    },
    onCalendarSelected(d) {
      this.model.modelYear = d ? d.getFullYear() : null
    },
    ...mapMutations(['setAppSnackbar'])
  },
  apollo: {
    model: {
      query() {
        const fields = this.getFieldsGql('read', 'Model', [
          'id',
          'name',
          'assetType',
          'modelNumber',
          'modelYear',
          'cmanageModel',
          { name: 'manufacturer', type: 'Manufacturer', fields: ['id', 'name'] }
        ])
        return this.$gql`
          query GetModel($id: ID!) {
            model(id: $id) {
              ${fields}
            }
          }
        `
      },
      variables() {
        return { id: this.id }
      },
      skip() {
        return !this.id
      },
      update: ({ model }) => ({
        name: model ? model.name : null,
        assetType: model ? model.assetType : null,
        modelNumber: model ? model.modelNumber : null,
        modelYear: model ? model.modelYear : null,
        cmanageModel: model ? model.cmanageModel : null,
        manufacturer: model ? model.manufacturer : null
      }),
      result({ data, error }) {
        if (error) {
          this.graphQLOnError(`Failed to get model data from server. ${error.toString()}`)
          this.model = {}
          if (error.toString().includes('Not found')) {
            this.renderError(404)
          }
        } else {
          this.$emit('name', this.model.name)
        }
      }
    },
    manufacturers: {
      query() {
        const fields = this.getFieldsGql('read', 'Manufacturer', ['id', 'name'])
        return this.$gql`
          {
            manufacturers(orderBy: {path: "name", descending: false}) {
              items {
                ${fields}
              }
            }
          }
        `
      },
      skip() {
        return !this.ability.can('read', 'Manufacturer')
      },
      update({ manufacturers }) {
        return manufacturers.items
      },
      result({ error }) {
        if (error) {
          this.graphQLOnError(`Failed to get manufacturers. ${error.toString()}`)
        }
      }
    }
  }
}
