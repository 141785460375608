import GraphQlMixin from '@/mixins/graphql.mixin'
import { mapMutations } from 'vuex'

export default {
  props: ['show'],
  mixins: [GraphQlMixin],
  data() {
    return {
      submitting: false,
      manufacturer: {
        name: null
      },
      rules: {
        required: [
          (v) => !!v || 'Required',
          (v) => (!!v && v.length <= 64) || 'Exceeds maximum length'
        ]
      },
      whereConditions: [],
      errorMessages: {
        name: []
      },
      debounceTimeout: null
    }
  },
  computed: {
    submitButtonDisabled() {
      return (
        _.isEmpty(this.manufacturer.name) || !_.isEmpty(this.errorMessages.name)
      )
    }
  },
  methods: {
    submit() {
      // remove errors
      this.removeErrorByType('form')

      // prepare payload
      const input = { name: this.manufacturer.name }
      const fields = this.getFieldsGql('read', 'Manufacturer', ['id', 'name'])

      this.submitting = true

      this.$apollo
        .mutate({
          // Query
          mutation: this.$gql`
          mutation createManufacturer($input: CreateManufacturerInput!) {
            createManufacturer(input: $input) {
              manufacturer {
                ${fields}
              }
            }
          }
        `,
          variables: { input }
        })
        .then(({ data }) => {
          const manufacturer = _.get(data, 'createManufacturer.manufacturer')
          if (manufacturer && manufacturer.id && manufacturer.name) {
            this.$emit('onmanufacturercreated', manufacturer)
            this.setAppSnackbar({
              text: `Created manufacturer #${manufacturer.id} ${manufacturer.name} successfully.`,
              color: 'success'
            })
            this.submitting = false
            this.closeDialog()
          } else {
            this.addError(null, 'Failed to create manufacturer.', 'form')
          }
        })
        .catch((e) => {
          this.addError(
            null,
            `Failed to create manufacturer. ${e.toString()}`,
            'form'
          )
          this.submitting = false
        })
    },
    closeDialog() {
      // reset variables
      this.manufacturer.name = null
      this.whereConditions = []
      this.errorMessages = {
        name: []
      }
      clearTimeout(this.debounceTimeout)
      this.debounceTimeout = null

      // close dialog
      this.$emit('update:show', false)
    },
    validateField(value, path, additionalRules = []) {
      // remove error message
      this.errorMessages[path] = []

      clearTimeout(this.debounceTimeout)
      this.debounceTimeout = setTimeout(() => {
        this.whereConditions = [
          {
            path,
            comparison: 'equal',
            value
          }
        ]
        // check other conditions if they have value
        for (let rule of additionalRules) {
          if (rule.value) {
            this.whereConditions.push(rule)
          } else {
            this.errorMessages[path].push(rule.path + ' not selected')
          }
        }
      }, 200)
    },
    ...mapMutations(['setAppSnackbar'])
  },
  apollo: {
    manufacturers: {
      query() {
        return this.$gql`
          query manufacturers($where: [WhereExpression]) {
            manufacturers(where: $where) {
              totalCount
            }
          }
        `
      },
      variables() {
        return {
          where: this.whereConditions
        }
      },
      skip() {
        return _.isEmpty(this.whereConditions)
      },
      result({ data, error }) {
        if (data && data.manufacturers) {
          const manufacturers = data.manufacturers
          const path = _.get(this.whereConditions, '[0].path')
          if (path && manufacturers.totalCount > 0) {
            this.errorMessages[path].push(
              'Manufacturer with same ' + path + ' exists'
            )
          }
        } else if (error) {
          this.graphQLOnError(
            `Failed to get manufacturer data from server. ${error.toString()}`
          )
        } else {
          this.graphQLOnError('Failed to connect to server.')
        }
      }
    }
  }
}
